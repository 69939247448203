export const environment = {
  production: true,
  REACT_APP_BUILD_ENVIRONMENT: 'production',
  apiBaseUrl: 'https://waygee-api-staging.azurewebsites.net/api/',
  authCookieDomain: 'waygee.io',
  authCookieExpirationInSeconds: 604800,
  authCookieIntervalCheckInMilliseconds: 3000,
  authCookieKey: 'authTokenStaging',
  authCookieRefreshTokenIntervalCheckInMilliseconds: 60000,
  idleToleranceInMinutes: 7,
  idleTimeToExpireSessionInMinutes: 10,
  lasRequestDateSessionId: 'lastRequestDate',
  signInUrl: 'https://app.waygee.io/secure/login',
  ozApiBaseUrl: 'http://server1.xavipay.com:8022/v1',
  disableSignup: false,
  disableOrderMfa: true,
  disableLoginMfa: true,
};
